import { Box } from "@mui/material";
import React from "react";
import { logo } from "../../assets/images";

const SuspenseLoader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Box
        component="img"
        sx={{
          width: "200px",
        }}
        src={logo}
      />
    </Box>
  );
};

export default SuspenseLoader;
