import {
  Box,
  List,
  ListItemButton,
  Collapse,
  Typography,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useLocation, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { toggleSideBar } from "../../store/slices/ui-slice";
import sidebarIcons from "./sidebarIcons";

const Sidebar = () => {
  const { filteredSidebar } = useSelector((state) => state.auth);

  const [openSections, setOpenSections] = useState({});
  const [activeParents, setActiveParents] = useState({});
  const [hoverLink, setHoverLink] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname: currentPath } = location;

  const handleClick = (title) => {
    setOpenSections((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };

  useEffect(() => {
    const updatedActiveParents = {};
    filteredSidebar.forEach((parent) => {
      if (parent?.Child_links) {
        const isChildActive = parent?.Child_links?.some((child) =>
          currentPath.includes(child.link)
        );
        if (isChildActive) {
          updatedActiveParents[parent.title] = true;
        }
      }
    });

    setActiveParents(updatedActiveParents);
    // return () => setOpenSections({});
  }, [currentPath]);

  return (
    <Box
      sx={{
        maxHeight: "calc(100vh - 150px)",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: 0, // Hide scrollbar initially in WebKit browsers
        },
        "&:hover::-webkit-scrollbar": {
          width: "4px", // Show scrollbar on hover in WebKit browsers
        },
      }}
    >
      <List
        sx={{
          pl: "1rem",
        }}
      >
        {filteredSidebar?.map((item, ind) =>
          item?.Child_links?.length > 0 ? (
            <React.Fragment key={ind}>
              <Box
                sx={{
                  display: "flex",
                  width: "200px",
                  justifyContent: "start",
                  alignItems: "center",
                  mt: "2rem",
                  backgroundColor:
                    // openSections[item.title] ||
                    activeParents[item.title] || hoverLink === item?.title
                      ? "primary.main"
                      : "transparent",
                  py: ".5rem",
                  borderRadius: "8px",
                  cursor: "pointer",
                  // px:'16px',
                  overflowX: "hidden",
                }}
                onClick={() => handleClick(item.title)}
                onMouseEnter={() => setHoverLink(item?.title)}
                onMouseLeave={() => setHoverLink(null)}
              >
                <Box
                  component={"img"}
                  src={
                    // openSections[item.title] ||
                    activeParents[item.title] || hoverLink === item?.title
                      ? sidebarIcons[item?.light_icon]
                      : sidebarIcons[item?.dark_icon]
                  }
                  // height="20px"

                  width="20px"
                  sx={{
                    display: "block",
                    ml: "15px",
                  }}
                />
                <Typography
                  sx={{
                    flex: 1,
                    fontSize: "14px",
                    pl: "10px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    color:
                      // openSections[item.title] ||
                      activeParents[item.title] || hoverLink === item.title
                        ? "primary.whiteText"
                        : "primary.blackText",
                  }} // Set fontSize for the parent item
                >
                  {item.title}
                </Typography>
                {openSections[item.title] ? (
                  <ExpandLess
                    sx={{
                      color:
                        // openSections[item.title] ||
                        activeParents[item.title] || hoverLink === item.title
                          ? "primary.whiteText"
                          : "primary.blackText",
                      ml: "auto",
                      mr: "5px",
                    }}
                  />
                ) : (
                  <ExpandMore
                    sx={{
                      color:
                        // openSections[item.title] ||
                        activeParents[item.title] || hoverLink === item.title
                          ? "primary.whiteText"
                          : "",
                      ml: "auto",
                      mr: "5px",
                    }}
                  />
                )}
              </Box>
              <Collapse
                in={openSections[item.title]}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {item.Child_links?.filter(
                    (child) => child?.link !== "/network-graph"
                  )?.map((child, childIndex) => (
                    <IconButton
                      key={childIndex}
                      onClick={() => {
                        dispatch(toggleSideBar());
                        navigate(child.link);
                      }}
                      sx={{
                        width: "100%",
                        mt: "1rem",
                        cursor: "pointer",
                        borderRadius: "4px",
                        textAlign: "start",
                        justifyContent: "start",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "13px !important",
                          ml: "2rem",
                          color:
                            currentPath === child?.link
                              ? "primary.main"
                              : "primary.blackText",
                          fontWeight: 400,
                          textDecoration: "none",
                        }} // Set fontSize for the child items
                      >
                        {child.title}
                      </Typography>
                    </IconButton>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ) : (
            <ListItemButton
              key={ind}
              sx={{
                mt: ind > 0 ? "2rem" : "none",
                width: "200px",
                justifyContent: "start",
                backgroundColor: currentPath.includes(item?.link)
                  ? "primary.main"
                  : "transparent",
                borderRadius: "8px",
                // px: "2rem",
                "&.MuiListItemButton-root:hover": {
                  backgroundColor: "primary.main",
                  color: "primary.whiteText",
                },
              }}
              onClick={() => {
                dispatch(toggleSideBar());
                navigate(item.link);
              }}
              onMouseEnter={() => setHoverLink(item?.title)}
              onMouseLeave={() => setHoverLink(null)}
            >
              <Box
                component={"img"}
                src={
                  currentPath.includes(item?.link) || hoverLink === item?.title
                    ? sidebarIcons[item?.light_icon]
                    : sidebarIcons[item?.dark_icon]
                }
                // height="20px"
                width="20px"
              />
              <Typography
                component={"span"}
                sx={{
                  fontSize: "13px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  ml: "10px",
                  color:
                    currentPath.includes(item?.link) ||
                    hoverLink === item?.title
                      ? "#fff "
                      : "primary.blackText",
                }} // Set fontSize for the non-collapsible items
              >
                {item.title}
              </Typography>
            </ListItemButton>
          )
        )}
      </List>
    </Box>
  );
};

export default Sidebar;
