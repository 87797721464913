import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Avatar,
  Button,
  Menu,
  Box,
  MenuItem,
  Typography,
  IconButton,
} from "@mui/material";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  LogoutRounded as LogoutRoundedIcon,
} from "@mui/icons-material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import {
  logout,
  setUserReducer,
  setUserRole,
} from "../../../store/slices/auth-slice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// import { useDispatch } from 'react-redux';
//actions
// import { setUser } from "../../features/authSlice"
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 150,
    color: "#252526",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: "16px",
        color: "#252526",
        marginRight: "0.3rem",
      },
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:active": {
        backgroundColor: "#9F9F9F55",
      },
    },
  },
}));
export default function UserAvatarMenu({
  name = "Admin",
  role = "Admin",
  avatarSrc = "https://placehold.co/300?text=A",
  nameColor = "#fff",
  roleColor = "primary.textPrimary",
}) {
  const { userObject } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleProfileNavigation = () => {
    navigate(`/settings`);
  };
  const handleSignOut = () => {
    dispatch(logout());
    setAnchorEl(null);
  };
  return (
    <Box>
      <Button
        id="custom-menu-button"
        aria-controls={open ? "custom-menu-body" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableRipple={true}
        disableTouchRipple={true}
        disableFocusRipple={true}
        variant="transparent-menu-btn"
        disableElevation
        onClick={handleClick}
        endIcon={
          <KeyboardArrowDownIcon
            sx={{ color: "white", display: { xs: "none", sm: "inline" } }}
          />
        }
        sx={{
          px: "3px",
        }}
      >
        <Box sx={{ display: "flex", gap: "0.7rem", alignItems: "center" }}>
          <Box>
            <Avatar
              src={process.env.REACT_APP_IMAGE_BASE_URL + userObject?.avatar}
              alt={userObject?.first_name || ""}
              sx={{
                borderRadius: "4px",
                color: "white",
              }}
            />
          </Box>
          {userObject?.first_name ||
          (userObject?.last_name && userObject?.Role?.display_name) ? (
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography
                noWrap
                sx={{
                  fontSize: "13px",
                  color: nameColor,
                  fontWeight: 600,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {(
                  userObject?.first_name +
                  " " +
                  userObject?.last_name
                ).substring(0, 25)}
              </Typography>
              <Typography
                sx={{
                  fontSize: "11px",
                  color: roleColor,
                  textTransform: "capitalize",
                }}
              >
                {userObject?.Role?.display_name}
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Button>
      <StyledMenu
        id="custom-menu-body"
        MenuListProps={{
          "aria-labelledby": "custom-menu-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleProfileNavigation} sx={{ fontSize: "16px" }}>
          <AccountCircleOutlinedIcon />
          Profile
        </MenuItem>
        <MenuItem onClick={handleSignOut}>
          <LogoutRoundedIcon />
          Sign out
        </MenuItem>
      </StyledMenu>
    </Box>
  );
}
