// Sidebar icons
import {
  DarkDashboardIcon,
  LightDashboardIcon,
  DarkIncidentsIcon,
  LightIncidentsIcon,
  DarkRegistriesIcon,
  LightRegistriesIcon,
  DarkAnalysisIcon,
  LightAnalysisIcon,
  DarkManagementIcon,
  LightManagementIcon,
  LightOrgIcon,
  DarkOrgIcon,
  DarkReportIcon,
  LightReportIcon,
  DarkNotificationIcon,
  LightNotificationIcon,
  DarkSupportIcon,
  LightSupportIcon,
  DarkSettingIcon,
  LightSettingIcon,
} from "../../assets/images/index";

const sidebarIcons = {
  DarkDashboardIcon,
  LightDashboardIcon,
  DarkIncidentsIcon,
  LightIncidentsIcon,
  DarkRegistriesIcon,
  LightRegistriesIcon,
  DarkAnalysisIcon,
  LightAnalysisIcon,
  DarkManagementIcon,
  LightManagementIcon,
  LightOrgIcon,
  DarkOrgIcon,
  DarkReportIcon,
  LightReportIcon,
  DarkNotificationIcon,
  LightNotificationIcon,
  DarkSupportIcon,
  LightSupportIcon,
  DarkSettingIcon,
  LightSettingIcon,
};
export default sidebarIcons;
