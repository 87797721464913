import { createTheme } from "@mui/material/styles";

const WebTheme = createTheme({
  palette: {
    primary: {
      main: "#43CED7",
      whiteCard: "#fff",
      grayCard: "#F1F4FA",
      blackText: "#3A4752",
      redText: "rgba(209, 9, 9, 1)",
      lightRed: "rgba(209, 9, 9, 0.2)",
      greenText: "rgba(3, 168, 158, 1)",
      blackText: "#3A4752",
      grayText: "#768B9E",
      whiteText: "#fff",
      blueText: "#0085E5",
      blueCard: "#0085E5",
      lightBlue: "rgb(0 133 229 / 16%)",
      lightGreen: "rgb(180 242 236)",
      background: "#E9EFFE ",
      inputBorder: "rgba(235, 241, 246, 1)",
      lightPink: "rgba(255, 240, 244, 1)",
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    h3: {
      fontFamily: "Poppins, sans-serif",
      fontWeight: 500, // You can adjust this as needed
      fontSize: "1rem", // Adjust the size as needed
      color: "#3A4752", // Set the color if needed
    },
    // Define other typography styles if needed
  },
  components: {
    // typography style
    MuiTypography: {
      defaultProps: {
        // fontFamily: "Poppins, sans-serif",
      },
      styleOverrides: {
        blackOpacity: {
          color: "#222",
          opacity: 0.5,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          margin: "auto",
          maxWidth: "1300px",
          Width: "100%",
          ...(ownerState?.noQuery && {
            [theme.breakpoints.up("lg")]: {
              maxWidth: "1300px",
              Padding: "0px !important",
            },
            [theme.breakpoints.up("sm")]: {
              maxWidth: "100%",
              Padding: "0px !important",
            },
            [theme.breakpoints.up("md")]: {
              maxWidth: "1300px",
              Padding: "0px !important",
            },
          }),
          ...(ownerState?.notMargin && {
            margin: "0px",
          }),
        }),
      },
    },
    // button style
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          border: "24px",
          fontFamily: "Poppins,Sans serif,sans",
          mixBlendMode: "normal",
          borderRadius: "3px",
          fontSize: "14px",
          textTransform: "none",
          boxShadow: "none",
          ...(ownerState.variant === "primary" && {
            color: "white",
            fontWeight: "normal",
            background: "#43CED7",
            borderRadius: "6px",
            fontSize: "14px",
            "&:hover": {
              background: "#43CED7", // Set the hover background color to red
            },
          }),
          ...(ownerState.variant === "secondary" && {
            color: "white",
            fontWeight: "normal",
            background: "rgba(27, 132, 255, 1)",
            borderRadius: "6px",
            "&:hover": {
              background: "rgba(27, 132, 255, 1)",
            },
          }),
          ...(ownerState.variant === "disabled" && {
            color: "#fff !important",
            fontWeight: "normal",
            background: "#A7A7A7",
            borderRadius: "3px",
          }),
          ...(ownerState.variant === "danger" && {
            background: "rgba(209, 9, 9, 0.2)",
            color: "rgba(209, 9, 9, 1)",
            // border: "2px solid #E73823",
            fontWeight: "normal",
            borderRadius: "6px",
          }),
          ...(ownerState.variant === "outlined" && {
            background: "transparent",
            color: "#424242",
            border: "1px solid #424242",
            fontWeight: "normal",
            borderRadius: "3.5px",
            "&:hover": {
              border: "1px solid #424242",
            },
          }),
          ...(ownerState.variant === "loginWithGoogle" && {
            background: "transparent",
            color: "#222",
            border: "1px solid #222",
            fontWeight: "normal",
            borderRadius: "3.5px",
            "&:hover": {
              border: "1px solid #222",
            },
          }),
        }),
      },
    },

    // checkbox style
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "rgba(235, 241, 246, 1)",
          "&.Mui-checked": {
            color: "rgba(67, 206, 215, 1)",
          },
        },
      },
    },
  },
});

export default WebTheme;
