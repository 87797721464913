import { lazy } from "react";
import { Navigate } from "react-router-dom";

import Layout from "./layout";

// Lazy load modules
const Login = lazy(() => import("./modules/Authentications/Login"));
const TwoFactor = lazy(() => import("./modules/Authentications/TwoFactor"));
const EmailVerify = lazy(() =>
  import("./modules/Authentications/ForgotPassword")
);
const ResetPassword = lazy(() =>
  import("./modules/Authentications/ResetPassword")
);
const Home = lazy(() => import("./modules/Home/Home"));
const Incidents = lazy(() => import("./modules/Incidents/Incidents"));
const IncidentDetails = lazy(() =>
  import("./modules/Incidents/IncidentDetails")
);
const IncidentListing = lazy(() =>
  import("./modules/Incidents/IncidentListing")
);
const AddIncidentForm = lazy(() =>
  import("./modules/Incidents/AddIncidentForm")
);
const IncidentApprovals = lazy(() =>
  import("./modules/Incidents/IncidentApprovals")
);
const Persons = lazy(() => import("./modules/Persons/Persons"));
const PersonDetail = lazy(() => import("./modules/Persons/PersonDetailPage"));
const AddPerson = lazy(() =>
  import("./modules/Persons/AddPerson")
);
const Organizations = lazy(() =>
  import("./modules/Organizations/Organization")
);
const OrgDetailPage = lazy(() =>
  import("./modules/Organizations/OrgDetailPage")
);
const AddOrganization = lazy(() =>
  import("./modules/Organizations/AddOrganization")
);
const Weapons = lazy(() => import("./modules/Weapons/Weapons"));
const AddEditWeapon = lazy(() => import("./modules/Weapons/AddEditWeapon"));
const ViewWeapon = lazy(() => import("./modules/Weapons/ViewWeapon"));
const Address = lazy(() => import("./modules/Address/Address"));
const AddEditAddress = lazy(() => import("./modules/Address/AddEditAddress"));
const Evidence = lazy(() => import("./modules/Evidence/Evidence"));
const EvidenceDetail = lazy(() => import("./modules/Evidence/EvidenceDetail"));
const AddEditEvidence = lazy(() =>
  import("./modules/Evidence/AddEvidenceForm")
);
const NetworkGraph = lazy(() => import("./modules/Network-Graph/NetworkGraph"));
const HeatMap = lazy(() => import("./modules/HeatMap/HeatMap"));
const UserListing = lazy(() => import("./modules/User/UserListing"));
const AddEditUser = lazy(() => import("./modules/User/AddEditUserForm"));
const IncidentReport = lazy(() => import("./modules/Reports/IncidentReports"));
// const IncidentReportDetails = lazy(() =>
//   import("./modules/Reports/IncidentReports/components/ReportDetail")
// );
const IncidentSummary = lazy(() =>
  import("./modules/Reports/IncidentReports/components/IncidentSummary")
);
const GeographicReport = lazy(() => import("./modules/Reports/Geographic"));
const InvolvedPersonsReport = lazy(() =>
  import("./modules/Reports/InvolvedPersons")
);
const ModusOperandiReport = lazy(() =>
  import("./modules/Reports/ModusOperandi")
);
const OrgReport = lazy(() => import("./modules/Reports/OrgReports"));
const ThreatLevelReport = lazy(() =>
  import("./modules/Reports/TreatLevelReport")
);
const ThreatForecast = lazy(() => import("./modules/Reports/ThreatForecast"));
const HistoricalTrends = lazy(() =>
  import("./modules/Reports/HistoricalTrends")
);
const Settings = lazy(() => import("./modules/Settings"));
const NotificationsList = lazy(() => import("./modules/Notifications"));
const NotificationsDetail = lazy(() =>
  import("./modules/Notifications/NotificationDetail")
);

const Support = lazy(() => import("./modules/Support"));
const TicketView = lazy(() => import("./modules/Support/TicketView"));

// Define routes
const routes = [
  {
    path: "/",
    element: <Layout />,
    isLogged: true,
    children: [
      { path: "", element: <Navigate to="dashboard" /> },
      { path: "dashboard", element: <Home /> },
      {
        path: "incidents",
        element: <Incidents />,
      },
      {
        path: "incidents/all",
        element: <IncidentListing />,
      },
      {
        path: "incidents/create",
        element: <AddIncidentForm />,
      },
      {
        path: "incidents/edit/:id",
        element: <AddIncidentForm />,
      },
      {
        path: "incidents/view/:id",
        element: <IncidentDetails />,
      },
      {
        path: "incidents/approvals",
        element: <IncidentApprovals />,
      },
      { path: "persons", element: <Persons /> },
      { path: "persons/view/:id", element: <PersonDetail /> },
      { path: "persons/add", element: <AddPerson /> },
      { path: "persons/edit/:id", element: <AddPerson /> },
      { path: "organizations", element: <Organizations /> },
      { path: "organizations/create", element: <AddOrganization /> },
      { path: "organizations/edit/:id", element: <AddOrganization /> },
      { path: "organizations/view/:id", element: <OrgDetailPage /> },
      { path: "organizations/network-graph/:id", element: <NetworkGraph /> },
      { path: "weapons", element: <Weapons /> },
      { path: "weapons/create", element: <AddEditWeapon /> },
      { path: "weapons/edit/:id", element: <AddEditWeapon /> },
      { path: "weapons/view/:id", element: <ViewWeapon /> },
      { path: "address", element: <Address /> },
      { path: "address/create", element: <AddEditAddress /> },
      { path: "address/edit/:id", element: <AddEditAddress /> },
      { path: "evidence", element: <Evidence /> },
      { path: "evidence/create", element: <AddEditEvidence /> },
      { path: "evidence/edit/:id", element: <AddEditEvidence /> },
      { path: "evidence/view/:id", element: <EvidenceDetail /> },
      { path: "all-users", element: <UserListing /> },
      { path: "all-users/add", element: <AddEditUser /> },
      { path: "all-users/edit/:id", element: <AddEditUser /> },
      { path: "settings", element: <Settings /> },
      // Reports
      {
        // Incident Heat Map
        path: "reports/incident-heat-map",
        element: <HeatMap />,
      },
      { path: "reports/incident", element: <IncidentSummary /> },
      {
        path: "reports/incident/view",
        isLogged: true,
        element: <IncidentSummary />,
      },
      //
      {
        path: "reports/predictive-threat-analysis",
        element: <ThreatForecast />,
      },
      {
        path: "reports/threat-level-distribution",
        element: <ThreatLevelReport />,
      },
      {
        //"Involved Persons"
        path: "reports/involved-person-report",
        element: <InvolvedPersonsReport />,
      },
      {
        //Organization Activity
        path: "reports/organization-report",
        element: <OrgReport />,
      },
      {
        //"Modus Operandi",
        path: "reports/modus-operandi-report",
        element: <ModusOperandiReport />,
      },
      {
        //Historical Trends",
        path: "reports/historical-trends",
        element: <HistoricalTrends />,
      },
      {
        // Geographic Distribution
        path: "reports/geographic-report",
        element: <GeographicReport />,
      },
      { path: "notifications", element: <NotificationsList /> },
      { path: "notifications/view/:id", element: <NotificationsDetail /> },
      { path: "support", element: <Support /> },
      { path: "support/view/:id", element: <TicketView /> },

      // Catch-all route to navigate to Home if no other route matches
      { path: "*", element: <Navigate to="/dashboard" /> },
    ],
  },
  {
    path: "/login",
    isLogged: false,
    element: <Login />,
  },
  {
    path: "/two-factor",
    isLogged: false,
    element: <TwoFactor />,
  },
  {
    path: "/forgot-password",
    isLogged: false,
    element: <EmailVerify />,
  },
  {
    path: "/verify-forgot-pwd-link/:id/:code/:type",
    isLogged: false,
    element: <ResetPassword />,
  },
  { path: "*", isLogged: false, element: <Navigate to="/login" /> },
  { path: "", isLogged: false, element: <Navigate to="/login" /> },
];

export default routes;
