import { Box, CircularProgress } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import React, { useEffect } from "react";
import { logo } from "../../assets/images";

const ExportLoader = ({}) => {
  useEffect(() => {
    // Disable scroll on mount
    document.body.style.overflow = "hidden";

    // Disable other scroll-related events
    const preventScroll = (e) => e.preventDefault();
    window.addEventListener("wheel", preventScroll, { passive: false });
    window.addEventListener("touchmove", preventScroll, { passive: false });

    // Cleanup function to re-enable scroll on unmount
    return () => {
      document.body.style.overflow = "";
      window.removeEventListener("wheel", preventScroll);
      window.removeEventListener("touchmove", preventScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
        position: "absolute",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 10000000,
      }}
    >
      <Box
        component="img"
        src={logo}
        sx={{
          marginLeft: "5px",
          marginBottom: "3px",
          height: "90px",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 10000001,
        }}
      >
        <CircularProgress size={110} />
      </Box>
    </Box>
  );
};

export default ExportLoader;
