import axios from "axios";
import { toast } from "react-hot-toast";
import store from "../store";
import { logout } from "../store/slices/auth-slice";

const api = axios.create({
  baseURL: process.env.REACT_APP_TERRORISM_SERVER,
  headers: { "X-Custom-Header": "foobar" },
});

const shownMessages = new Set();

const showToast = (type, message) => {
  if (shownMessages.has(message)) return;

  if (type === 'error') toast.error(message);
  else if (type === 'success') toast.success(message);

  shownMessages.add(message);

  setTimeout(() => {
    shownMessages.delete(message);
  }, 5000);
};

api.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem("token");
    const pathname = window.location.pathname;
    config.headers.originPath = pathname;
    config.headers.authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    const { data } = response;
    if (data.notify) {
      showToast('success', data.message ?? "Success");
    }
    return response.data;
  },
  (error) => {
    const { response } = error;
    console.log(response, 'response')
    if (response.data.notify === true && response.status === 401) {
      showToast('error', `(401) ${response?.data?.message}`);
      store.dispatch(logout());
      localStorage.clear();
    } else if (response.data.notify === true) {
      showToast('error', response?.data?.message);
    } else if (response.status != 200) {
      showToast('error', response?.data[0]);
    }

    return Promise.reject(error);
  }
);

export default api;
