import { createSlice } from "@reduxjs/toolkit";

const chatSlice = createSlice({
  name: "chat",
  initialState: {
    messagesList: [],
    ticketStatus: "",
  },
  reducers: {
    addMessage: (state, action) => {
      state.messagesList.push(action.payload);
    },
    setMessagesList: (state, action) => {
      state.messagesList = action.payload;
    },
    setTicketStatus: (state, action) => {
      state.ticketStatus = action.payload;
    },
  },
});

export const { addMessage, setMessagesList, setTicketStatus } =
  chatSlice.actions;
export default chatSlice.reducer;
