import "./App.css";

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";

import routes from "./routes";
import WebTheme from "./Theme/WebTheme";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";
import { Suspense } from "react";
import SuspenseLoader from "./components/Loader/SuspenseLoader";

// FIXME: Remove delete noti from command

function App() {
  const { isLoggedIn } = useSelector((state) => state.auth);
  let Routes = [];
  if (isLoggedIn) {
    Routes = [...routes];
  } else {
    Routes = [...routes.filter((route) => !route.isLogged)];
  }
  const routerLinks = createBrowserRouter(Routes);
  return (
    <>
      <ThemeProvider theme={WebTheme}>
        <Suspense fallback={<SuspenseLoader />}>
          <Toaster position="bottom-center" reverseOrder={false} />
          <RouterProvider router={routerLinks} />
        </Suspense>
      </ThemeProvider>
    </>
  );
}

export default App;
