import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import { AppBar, Box, Toolbar, IconButton, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { showSearchPopUP, toggleSideBar } from "../../store/slices/ui-slice";

import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationMenu from "./component/NotificationMenu";
import UserAvatarMenu from "./component/UserAvatarMenu";

import { SearchIcon as searchIcon } from "../../assets/images";
import { AppConstants } from "../../constants";

// Styled search container (non-writable)
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: "2.2rem",
  width: "100%",
  cursor: "pointer",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

// Search Icon Wrapper
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.primary.whiteText,
}));

// Non-writable box styled to look like an input
const NonWritableBox = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.whiteText,
  padding: theme.spacing(1, 1, 1, 0),
  paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    width: "100%",
  },
}));

// Ctrl + K Hint style
const HintText = styled(Typography)(({ theme }) => ({
  marginLeft: "auto",
  backgroundColor: alpha(theme.palette.common.white, 0.2),
  borderRadius: "4px",
  padding: theme.spacing(0.5, 1),
  color: theme.palette.common.white,
  fontSize: "12px",
}));

export default function PrimarySearchAppBar({ className = "" }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Function to open search pop-up
  const handleSearchPopUp = () => {
    dispatch(showSearchPopUP());
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        sx={{
          backgroundColor: "primary.blueCard",
          pr: { xs: "3px", sm: "10px" },
        }}
        className={className}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            aria-label="open drawer"
            sx={{
              mr: 0.5,
              display: { xs: "block", md: "none", color: "#fff " },
            }}
            onClick={() => dispatch(toggleSideBar())}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            sx={{
              display: "block",
              fontWeight: 500,
              color: "primary.whiteText",
              cursor: "pointer",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: { xs: "150px", md: "200px" },
            }}
            onClick={() => navigate("/")}
          >
            {AppConstants.appName}
          </Typography>

          {/* Non-writable search field */}
          <Search
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "space-between",
              flexGrow: 1,
            }}
            onClick={handleSearchPopUp}
          >
            <SearchIconWrapper>
              <Box
                component="img"
                src={searchIcon}
                sx={{
                  filter: "brightness(2.5)",
                  height: 30,
                }}
              />
            </SearchIconWrapper>
            <NonWritableBox sx={{ width: "100%" }}>
              <Typography variant="body2" sx={{ color: "#fff" }}>
                Search...
              </Typography>
              <HintText variant="body2">Ctrl + K</HintText>
            </NonWritableBox>
          </Search>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: "flex", gap: "5px" }}>
            <IconButton
              sx={{
                display: { xs: "block", md: "none" },
              }}
              onClick={handleSearchPopUp}
            >
              <Box
                component="img"
                src={searchIcon}
                sx={{
                  filter: "brightness(1.5)",
                  height: 30,
                }}
              />
            </IconButton>
            <NotificationMenu sx={{ color: "#fff" }} />
            <UserAvatarMenu />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
