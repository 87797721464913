export const threatLevels = [
  { value: "high", label: "High" },
  { value: "medium", label: "Medium" },
  { value: "low", label: "Low" },
];
export const uiConstants = {
  sidBarWidth: "240px",
};
export const AppConstants = {
  appName: "RTDC Bureau",
};
